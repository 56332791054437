import { InjectionToken } from '@angular/core';
import { type ParagraphTypes } from '@big-direkt/utils/shared';

export interface ParagraphsMapItem {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import: () => Promise<any>;
    component: string;
    type: ParagraphTypes;
}

export type ParagraphsMap = ParagraphsMapItem[];

export const PARAGRAPHS_MAP = new InjectionToken<ParagraphsMap>('paragraphs map');
