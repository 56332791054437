import { NgModule } from '@angular/core';
import { ParagraphsHostDirective } from './directives/paragraphs-host.directive';
import { HolderComponent } from './components/holder/holder.component';

@NgModule({
    declarations: [
        HolderComponent,
        ParagraphsHostDirective,
    ],
    exports: [HolderComponent],
})
export class ParagraphsHolderModule {
}
