import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, Input, ViewChild, inject, type OnChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { type ParagraphModel } from '@big-direkt/utils/shared';
import { ParagraphsHostDirective } from '../../directives/paragraphs-host.directive';
import { ParagraphsComponentMapService } from '../../services/paragraphs-component-map.service';

@Component({
    selector: 'big-paragraphs-holder',
    templateUrl: './holder.component.html',
    styleUrl: './holder.component.css',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false,
})
export class HolderComponent implements OnChanges {
    private readonly destroyRef = inject(DestroyRef);
    private readonly paragraphsComponentMapService = inject(ParagraphsComponentMapService);

    @Input() public data?: ParagraphModel[];
    @HostBinding('class') public classList = 'block';

    @ViewChild(ParagraphsHostDirective, { static: true }) public paragraphsHost!: ParagraphsHostDirective;

    public ngOnChanges(): void {
        this.paragraphsHost.viewContainerRef.clear();

        if (this.data === undefined) {
            return;
        }

        this.paragraphsComponentMapService.map(this.data, this.paragraphsHost).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }
}
