import { Component, Input } from '@angular/core';
import { type ParagraphModel } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-paragraph-component-base',
    template: '',
    standalone: false,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class ParagraphComponentBase {
    @Input() public data?: ParagraphModel;
}
