import { inject, Injectable, type InjectionToken, Injector, type Type } from '@angular/core';
import { type ParagraphTypes } from '@big-direkt/utils/shared';
import { type ParagraphComponentBase } from '../components/paragraph-component-base';

type TypeToken = InjectionToken<Type<ParagraphComponentBase>>;

@Injectable({
    providedIn: 'root',
})
export class ParagraphComponentTypeInjectorService {
    public static readonly tokens: Partial<Record<ParagraphTypes, TypeToken>> = {};

    private readonly injector = inject(Injector);

    public get(type: ParagraphTypes): Type<ParagraphComponentBase> | undefined {
        const token: TypeToken | undefined = ParagraphComponentTypeInjectorService.tokens[type];

        if (!token) {
            return undefined;
        }

        try {
            return this.injector.get(token);
        } catch {
            return undefined;
        }
    }
}
