import { inject, Injectable, type Type } from '@angular/core';
import { type ParagraphModel } from '@big-direkt/utils/shared';
import { combineLatest, exhaustMap, from, map, of, switchMap, type Observable } from 'rxjs';
import { type ParagraphComponentBase } from '../components/paragraph-component-base';
import { type ParagraphsHostDirective } from '../directives/paragraphs-host.directive';
import { PARAGRAPHS_MAP } from '../paragraphs-map';
import { ParagraphComponentTypeInjectorService } from './paragraph-component-type-injector.service';

@Injectable({
    providedIn: 'root',
})
export class ParagraphsComponentMapService {
    private readonly paragraphComponentTypeInjector = inject(ParagraphComponentTypeInjectorService);
    private readonly lazyParagraphsMap = inject(PARAGRAPHS_MAP);

    public map(paragraphs: ParagraphModel[], host: ParagraphsHostDirective): Observable<void> {
        const paragraphsObservables = paragraphs.map(item => of(item).pipe(
            exhaustMap((paragraph: ParagraphModel) => {
                const componentMap = this.lazyParagraphsMap.find(paragraphMapItem => paragraphMapItem.type === paragraph.type);

                return typeof componentMap === 'undefined'
                    ? of([paragraph, this.paragraphComponentTypeInjector.get(paragraph.type)])
                    : from(componentMap.import().then(m => {
                        // eslint-disable-next-line sonarjs/no-nested-functions
                        const component = Object.keys(m).find(key => key.endsWith('Component'));

                        if (!component) {
                            // eslint-disable-next-line no-console
                            console.error('Could not find a component in module for paragraph type', paragraph.type);

                            return void 0;
                        }

                        return m[component];
                    })).pipe(
                        switchMap(component => of([
                            paragraph,
                            component,
                        ])),
                    );
            }),
        ));

        return combineLatest(paragraphsObservables).pipe(map(res => {
            res.forEach(([paragraph, component]) => {
                if (component === undefined) {
                    // eslint-disable-next-line no-console
                    console.error('Could not inject type for paragraph', paragraph.type);

                    return;
                }

                const ref = host.viewContainerRef.createComponent<ParagraphComponentBase>(component as Type<ParagraphComponentBase>);
                ref.setInput('data', paragraph);
            });
        }));
    }
}
